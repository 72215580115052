import React, { useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { UserIcon, ChatBubbleLeftRightIcon, HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';


interface ChatMessageProps {
    role: 'user' | 'assistant';
    content: string;
}

const negativeRatingReasons = [
    "Die Antwort ist falsch",
    "Die angegebenen Verweise sind unpassend",
    "Der Kontext ist nicht richtig",
    "Hatte eine bessere Hilfe erwartet",
    "Ein anderer Grund"
]

const Feedback = () => {
    const [rating, setRating] = useState<'positive' | 'negative' | undefined>();
    const [reason, setReason] = useState<string>();
    const [isSelectingReason, setIsSelectingReason] = useState<boolean>(false);

    const updateRating = (type: 'positive' | 'negative') => {
        const isRevoke = rating === type;
        setRating(isRevoke ? undefined : type)
        setReason(undefined);
        setIsSelectingReason(!isRevoke && type === 'negative');
    }
    const selectReason = (reasonIndex: number) => {
        if (reasonIndex >= 0) {
            const reason = negativeRatingReasons[reasonIndex];
            setReason(reason);
            setIsSelectingReason(false);
        } else {
            setReason(undefined);
        }
    }
    return (
        <span className={`feedback ${rating} ${rating ? 'active': undefined}`}>

            <HandThumbUpIcon className={`positive`} onClick={() => updateRating('positive')} title='Das ist eine TOP Antwort.' style={rating === 'positive' ? { background: 'green', color: 'white' } : {}} />
            <HandThumbDownIcon className={`negative`} onClick={() => updateRating('negative')} title="Diese Antwort passt nicht" style={rating === 'negative' ? { background: 'red', color: 'white' } : {}} />
            { isSelectingReason && 
                <select onChange={(evt) => selectReason(parseInt(evt.target.value))}>
                    <option>Darf ich den Grund erfahren?</option>
                    { negativeRatingReasons.map((negativeRatingReason, index) => {
                        return <option key={index} selected={negativeRatingReason === reason} value={index}>{negativeRatingReason}</option>
                    })}
                </select>
            }
            { reason && !isSelectingReason &&
                <span onClick={() => setIsSelectingReason(true)}>{reason}</span>
            }
        </span>
    )
}

const ChatMessage: React.FC<ChatMessageProps> = ({ role, content }) => {
    const isUser = role === 'user';
    const isImageContent = content && content.indexOf(';base64,') > 0;

    if (!role) {
        return (
            <div className='conversation-start'>
                <hr />
                <div>Neuer Unterhaltungsstart: {content}</div>
            </div>
        )
    }
    return (
        <div className={`flex gap-3 my-4 text-gray-600 ${isUser ? 'justify-end' : 'justify-start'}`} >
            <span className="relative flex shrink-0 rounded-full w-12 h-12">
                <div className="rounded-full bg-gray-100 border p-1">
                    {isUser ? (
                        <UserIcon className="h-9 w-9 text-black" />
                    ) : (
                        <ChatBubbleLeftRightIcon className="h-9 w-9 text-black" />
                    )}
                </div>
            </span>
            <div className={`leading-relaxed ${isUser ? 'question' : 'answer'}`} style={{ width: isImageContent && isUser ? '50%' : undefined }}>
                <span className="block font-bold text-gray-700" >
                    <span style={{ fontSize: '2em', lineHeight: '2em' }}>{isUser ? 'Du' : 'San Gallus'}</span>
                    {!isUser &&
                        <>
                            <span className="font-bold text-gray-700">&nbsp;&nbsp;(Lernender 2. Lehrjahr)</span>
                            <Feedback />
                        </>
                    }
                </span>
                <div className="prose text-gray-500" style={{ fontSize: '1.5em', lineHeight: '1.15em' }} >
                    {isImageContent &&
                        <img src={content} />
                    }
                    {!isImageContent &&
                        <Markdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                a({ href, children, className, ...props }) {
                                    return <a href={href} target="_blank" rel="noopener noreferrer" className={`break-all ${className}`} {...props}>{children}</a>;
                                }
                            }}
                        >
                            {content}
                        </Markdown>
                    }
                </div>
            </div>
        </div>
    );
};

export default ChatMessage;