import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { ThreadProvider } from './ThreadContext';
import ChatComponent from './ChatComponent';
import './index.css';

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex flex-col min-h-screen bg-overlay-background bg-no-repeat bg-cover bg-left">
        <main className="flex-grow w-full ">
          <ThreadProvider>
            <ChatComponent />
          </ThreadProvider>
        </main>
      </div>
    </QueryClientProvider>
  );
}

export default App;
