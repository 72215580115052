import React, { useRef, useEffect } from 'react';
import ChatMessage from './ChatMessage';

interface ChatContainerProps {
    messages: { id: string, role: string, content: string }[];
    currentMessage: string;
}

const ChatContainer: React.FC<ChatContainerProps> = ({ messages, currentMessage }) => {
    return (
        <div className="pr-4 overflow-auto">
            {messages.map((msg, index) => (
                <ChatMessage key={index} role={msg.role as 'user' | 'assistant'} content={msg.content} />
            ))}
            {currentMessage && (
                <ChatMessage role="assistant" content={currentMessage} />
            )}
        </div>
    );
};

export default ChatContainer;