import React from "react";

const BouncingDotsLoader = () => {
  return (
      <>
      <div className="bouncing-loader">
        <div className="bulb"></div>
        <div className="bulb"></div>
        <div className="bulb"></div>
      </div>
      </>
  );
};

export default BouncingDotsLoader;