import Webcam from "react-webcam";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { IBlobEvent, IMediaRecorder, MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

import { MicrophoneIcon } from "@heroicons/react/24/outline";
import { useAzureSpeech } from "./hooks/useAzureSpeech";


export type RecordedData = {
    base64Image?: string;
    text?: string;
}

let __recordedImage: string = ''

export const MediaRecording = ({ recording, onDataAvailable, onStartRecording, aspectRatio }: { recording: 'audio' | 'image' | 'all', onStartRecording: () => void, onDataAvailable: (data: RecordedData) => void, aspectRatio?: number }) => {
    const webcamRef = React.useRef<Webcam>(null);
    const azureSpeech = useAzureSpeech();
    const audioRecorderRef = React.useRef<IMediaRecorder>();
    const [mediaRecorderRegistered, setMediaRecorderRegistered] = useState<boolean>(false);
    const [audioCapturingStatus, setAudioCapturingStatus] = useState<'starting' | 'started' | 'stopped'>('stopped');

    const [stopped, setStopped] = useState<boolean>(false);

    const onToggleAudioCapturing = () => {
        setAudioCapturingStatus(audioCapturingStatus === 'stopped' ? 'starting' : 'stopped')
    }
    useEffect(() => {
        if (audioCapturingStatus === 'started') {
            onStartRecording();
        }
    }, [audioCapturingStatus])

    const registerMediaRecorder = async () => {
        try {
            const messagePort = await connect();
            await register(messagePort);
        } catch (ex) {
            console.log("ERROR registering message port", ex);
        } finally {
            setMediaRecorderRegistered(true);
        }
    }
    useEffect(() => {
        registerMediaRecorder();
    }, []);

    const notifyParent = useCallback(async (recordedAudioBlob?: Blob) => {
        const text = (recordedAudioBlob && recordedAudioBlob.size > 0)
            ? await azureSpeech.transcribeAudio(recordedAudioBlob)
            : undefined;
        onDataAvailable({ text, base64Image: __recordedImage });
    }, [onDataAvailable]);

    const onWebcamReady = React.useCallback(async () => {
        if (webcamRef.current?.stream && (recording === 'audio' || recording === 'all')) {
            const audioStream = new MediaStream();
            for (const track of webcamRef.current?.stream.getAudioTracks()) {
                audioStream.addTrack(track);
            }
            audioRecorderRef.current = new MediaRecorder(audioStream, { mimeType: 'audio/wav' });
            audioRecorderRef.current.addEventListener(
                "dataavailable",
                (e: IBlobEvent) => notifyParent(e.data)
            );
            audioRecorderRef.current.start();
        }
        if (audioCapturingStatus === 'starting') {
            setAudioCapturingStatus('started');
        }
    }, [webcamRef, audioRecorderRef, audioCapturingStatus, setAudioCapturingStatus, notifyParent, recording]);

    const onStopWebcam = useCallback(() => {
        if (recording === 'image' || recording === 'all') {
            let imageSrc = webcamRef.current?.getScreenshot();
            if (imageSrc) {
                __recordedImage = imageSrc;
            }
        }
        setStopped(true);

        if (recording === 'audio' || recording === 'all') {
            audioRecorderRef.current?.stop();
        } else {
            notifyParent();
        }
    }, [webcamRef, audioRecorderRef, notifyParent, recording]);

    const microColor = audioCapturingStatus === 'starting' ? 'orange' : audioCapturingStatus === 'started' ? 'rgb(193,0,0)' : 'black';
    return (
        <>
            {recording === 'audio' &&
                <MicrophoneIcon className="h-24 w-24 mr-8 pointer" style={{ cursor: 'pointer', color: microColor }} onClick={onToggleAudioCapturing} />
            }
            {mediaRecorderRegistered && (recording !== 'audio' || audioCapturingStatus === 'starting' || audioCapturingStatus === 'started') &&
                <div className="flex justify-center items-center w-fullpp" style={{ display: recording === 'audio' ? 'none' : 'block' }} >
                    {
                        !stopped &&
                        <>
                            <div style={{
                                position: 'absolute',
                                padding: '10px',
                                width: 'calc(100% - 96px)',
                                textAlign: 'center',
                                background: '#eee',
                                marginTop: '10px',
                                marginLeft: '24px',
                                color: '#096bb7'
                            }}>Anweisung sprechen und dann mit Klick auf Video den Screenshot erstellen</div>
                            <Webcam
                                style={{ width: '100%' }}
                                muted={true}
                                audio={true}
                                ref={webcamRef}
                                onUserMedia={onWebcamReady}
                                onClick={onStopWebcam}
                                onUserMediaError={(e) => console.log("Media Error", e)}
                                screenshotFormat="image/jpeg"
                                videoConstraints={{
                                    aspectRatio: { exact: aspectRatio || 1 },
                                    facingMode: window.location.href.indexOf('localhost') >= 0 ? "user" : { exact: "environment" }
                                }}
                                audioConstraints={{
                                    noiseSuppression: true,
                                    echoCancellation: true
                                }} />
                        </>
                    }
                    {
                        stopped &&
                        <>
                            <div style={{
                                position: 'absolute',
                                background: '#00000033',
                                left: 0,
                                right: 0,
                                bottom: 0,
                                top: 0
                            }} />
                            <img src={__recordedImage} style={{ width: '100%' }} />
                        </>
                    }
                </div>
            }

        </>
    );
};