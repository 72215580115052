import Cookie from 'universal-cookie';
import { ResultReason, SpeechRecognizer } from "microsoft-cognitiveservices-speech-sdk";
import { resolveApiEndpoint } from "../ApiEndpointResolver";
import { useState } from 'react';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk')

const obtainSpeechToken = async (): Promise<{ authToken?: string, region?: string, error?: any }> => {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');
    if (speechToken === undefined) {
        try {
            const res = await fetch(resolveApiEndpoint('SpeechToken'));
            const data: { token: string, region: string } = await res.json();
            cookie.set('speech-token', data.region + ':' + data.token, { maxAge: 540, path: '/' });
            return { authToken: data.token, region: data.region };
        } catch (err) {
            console.log(err);
            return { authToken: undefined, error: err };
        }
    } else {
        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}


const transcribeAudio = async (audio: Blob): Promise<string> => {
    const tokenObj = await obtainSpeechToken();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
    speechConfig.speechRecognitionLanguage = 'de-CH';
    const audioFile = new File([audio], `${Date.now()}.wav`)
    const audioConfig = speechsdk.AudioConfig.fromWavFileInput(audioFile);
    const recognizer: SpeechRecognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
    return new Promise<string>((resolve, reject) => {
        recognizer.recognizeOnceAsync(result => {
            if (result.reason === ResultReason.RecognizedSpeech) {
                resolve(result.text);
            } else if (result.reason === ResultReason.NoMatch) {
                resolve("");
            } else {
                console.log('ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.');
                reject();
            }
        }, (err) => {
            console.log("error in trascribin", err)
            reject();
        });
    })
}

const textToVoice = async (text: string): Promise<{ stop: ()=> void }> => {
    console.log("Text to Speech")
    const tokenObj = await obtainSpeechToken();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
    const player = new speechsdk.SpeakerAudioDestination();
    const audioConfig  = speechsdk.AudioConfig.fromSpeakerOutput(player);
    speechConfig.speechSynthesisVoiceName = "de-CH-LeniNeural";
    var synthesizer = new speechsdk.SpeechSynthesizer(speechConfig, audioConfig);
    let isSynthesizing = false;
    synthesizer.speakTextAsync(text,
        function (result: any) {
            isSynthesizing = true;
            if (result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted) {
                console.log("synthesis finished.");
            } else {
                console.error("Speech synthesis canceled, " + result.errorDetails +
                    "\nDid you set the speech resource key and region values?");
            }
            synthesizer.close();
            synthesizer = null;
        },
        function (err: any) {
            console.trace("err - " + err);
            synthesizer.close();
            synthesizer = null;
        }
    );
    return player;
}

export const useAzureSpeech = () => {
    const [ player, setPlayer] = useState<any>();
    return {
        transcribeAudio,
        textToVoice: async (text: string) => {
            const player = await textToVoice(text);
            setPlayer(player);
        },
        stopSpeaking: () => {
            if (player) {
                player.pause();
            }
        }
    }
}