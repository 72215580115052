import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useThread } from './ThreadContext';
import ChatContainer from './ChatContainer';
import ChatInput from './ChatInput';
import { resolveApiEndpoint } from './ApiEndpointResolver';
import BouncingDotsLoader from './BouncingDotsLoader';
import { useAzureSpeech } from './hooks/useAzureSpeech';
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline';

const getContextDataStrategy = (): 'Google' | 'AiStudioEquivalent' => {
    return window.location.search && window.location.search.toLocaleLowerCase().indexOf("google") >= 0
        ? 'Google'
        : 'AiStudioEquivalent';
}


const LinkRow = ({onReset}: {onReset: () => void}) => {
    return (
        <div className='link-row'>
            <a href="https://www.sg.ch/#stq=" target='_blank'>Zur konventionellen Suche wechseln</a>
            <span>|</span>
            <a href="#" onClick={() => onReset()}>Aktuelle Konversation abbrechen</a>
        </div>
    )
}

const ChatComponent: React.FC = () => {
    const { threadId, setThreadId } = useThread();
    const [messages, setMessages] = useState<{ id: string, role: string, content: string }[]>([]);
    const [currentMessage, setCurrentMessage] = useState<string>('');
    const [contextDataStrategy] = useState<string>(getContextDataStrategy());
    const [isSending, setIsSending] = useState<boolean>(false);
    const chatWindowRef = useRef<HTMLDivElement>(null);
    const azureSpeech = useAzureSpeech();
    const [textToVoice, setTextToVoice] = useState(false);

    const chatContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight + 100;
        }
    }, [isSending, messages, currentMessage]);

    const createThread = useCallback(async () => {
        const response = await fetch(resolveApiEndpoint('Thread'), { method: 'PUT' });
        const data = await response.json();
        setThreadId(data.threadId);
    }, [setThreadId]);

    const onResetConversation = async (resetMessages?: boolean) => {
        if (!isSending) {
            setCurrentMessage('');
            if (resetMessages) {
                setMessages([]);
            } else {
                const now = new Date();
                setMessages([...messages, {id: `${now.getTime()}`, content: now.toLocaleTimeString(), role: ''}]);
            }
            await createThread();
        }
    }

    useEffect(() => {
        if (!threadId) {
            createThread();
        }
    }, [threadId, createThread]);


    const sendMessage = async (msg?: string, base64Image?: string) => {
        if ((!msg || !msg.trim()) && !base64Image) return;

        azureSpeech.stopSpeaking();
        setIsSending(true);
        const updatedMessagesList = messages;
        if (base64Image) {
            updatedMessagesList.push({ id: `image-${Date.now()}`, role: 'user', content: base64Image });
        }
        if (msg) {
            updatedMessagesList.push({ id: `text-${Date.now()}`, role: 'user', content: msg });
        }
        setMessages(updatedMessagesList);

        const imageData = (base64Image && base64Image.indexOf(';base64,') > 0) ? base64Image.substring(base64Image.indexOf(';base64,') + 8) : base64Image;
        const response = await fetch(resolveApiEndpoint('Message'), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message: msg, threadId, imageData: imageData, contextDataStrategy: contextDataStrategy }),
        });

        setIsSending(false);
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let buffer = '';

        while (!done) {
            const { value, done: doneReading } = await reader?.read()!;
            done = doneReading;
            const chunk = decoder.decode(value, { stream: true });
            buffer += chunk;
            setCurrentMessage(buffer);

            if (done) {
                if (textToVoice) {
                    azureSpeech.textToVoice(buffer);
                }
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { id: `${Date.now()}`, role: 'assistant', content: buffer },
                ]);
                setCurrentMessage('');
            }
        }
    };

    return (
        <div className="fixed mr-4 bg-white p-6 rounded-lg border border-gray-200 flex flex-col w-[95%] max-w-full h-auto shadow-md "
            style={{ width: '96%', margin: '3% 2%', top: 0, bottom: 0 }}
            ref={chatContainerRef}>
            <div className="flex-none flex items-center justify-between">
                <div className="flex justify-between items-center w-full">
                    <div className="flex items-center p-4 bg-white rounded-lg">
                        <img src="https://www.sg.ch/apps/core/docroot/images/design/sgch/logo-sg-wappen.png" alt="Logo" className="h-16 mr-2" />
                        <h1 className="text-xl font-semibold" style={{ fontSize: '2em', marginLeft: '20px' }} >San Gallus {contextDataStrategy === 'Google' && ' (Websearch)'}</h1>
                    </div>
                    {messages.length > 0 && !isSending && !currentMessage && 
                        <button className="flex items-center bg-white rounded-lg enabled:hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 pointer" onClick={() => onResetConversation(true)}>
                            <span style={{ marginRight: '10px' }}>Neues starten</span> <ArrowLeftStartOnRectangleIcon title='Neues Thema starten' className={`h-16 w-16 text-teal-700`} />
                        </button>
                    }
                </div>
            </div>
            {threadId ? (
                <>
                    <div className="overflow-auto mb-4" ref={chatWindowRef}>
                        <ChatContainer
                            messages={messages}
                            currentMessage={currentMessage}
                        />
                    </div>
                    {isSending &&
                        <BouncingDotsLoader />
                    }
                    { !currentMessage && messages.length > 0 && !isSending && (
                        <LinkRow onReset={onResetConversation}/>
                    )}
                    <ChatInput onMessageUpdate={sendMessage} disabled={isSending} />
                </>
            ) : (
                <div className="flex justify-center items-center h-full">
                    <p className="text-gray-500">Creating chat session...</p>
                </div>
            )}
        </div>
    );

};

export default ChatComponent;