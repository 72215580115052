import React, { useEffect, useState } from 'react';

import { MediaRecording, RecordedData } from './MediaRecording';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

interface ChatInputProps {
    onMessageUpdate: (msg: string) => void;
    disabled: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onMessageUpdate, disabled }) => {
    const [input, setInput] = useState<string>('');

    useEffect(() => {
        if (!disabled) {
            setInput('');
        }
    }, [disabled])


    const notifyParent = (text: string) => {
        setInput('');
        onMessageUpdate(text);
    }
    const onAudioCaptured = (recordedData: RecordedData) => {
        if (recordedData.text) {
            notifyParent(recordedData.text);
        }
    }

    return (
        <div className="flex-none items-center pt-0">
            <div className="flex items-center justify-center w-full space-x-2">
            <MediaRecording recording="audio" onDataAvailable={onAudioCaptured} onStartRecording={() => {}}/>
                <input
                    className="flex w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                    placeholder={disabled ? "Anfrage wird beantwortet...": "Starte deine Anfrage..."}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && notifyParent(input)}
                    disabled={disabled}
                    style={{fontSize: '2em'}}
                />
                <PaperAirplaneIcon className="h-20 w-20 pl-2 disabled:pointer-events-none disabled:opacity-50" style={{cursor: 'pointer'}} onClick={() => notifyParent(input)}/>
            </div>
            
        </div>
    );
};

export default ChatInput;